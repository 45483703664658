<template>
<div class="card-box-full">
  <div class="card-box-header">
    Recent Messages
    <div style="float: right; margin-right: 10px;">
      <div v-if="this.refreshTimer != null" style="cursor: pointer" @click="clickedPause()">
        <CIcon name="cil-media-pause" style="width: 20px; height: 20px" />
      </div>
      <div v-else style="cursor: pointer" @click="clickedPlay()">
        <CIcon name="cil-media-play" style="width: 20px; height: 20px" />
      </div>
    </div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="errorMessage">
    <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="isRefreshing && messages.length == 0">
    <div class="spinner-border spinner-border-sm text-primary" role="status" style="margin: 13px 0px 0px 10px">
      <span class="sr-only"></span>
    </div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-else>
    <div name="live-table" is="transition-group">
      <div v-for="message in messages" class="live-table-item" :key="message.id" style="border-bottom: 1px solid #d8dbe0">

        <!-- -------------------------------- -->
        <div style="width: 90px; height: 70px; text-align: center; position: relative; display: inline-block;">
          <div style="height: 14px"></div>
          <div v-if="message.reason == 'domain_reputation'">
            <span class="badge badge-pill badge-light">Domain</span>
          </div>
          <div v-else-if="message.reason == 'fingerprint_override'">
            <span class="badge badge-pill badge-light">Fingerprint</span>
          </div>
          <div v-else-if="message.reason == 'ml_model'">
            <span class="badge badge-pill badge-light">ML Model</span>
          </div>
          <div v-else-if="message.reason == 'sender_override'">
            <span class="badge badge-pill badge-light">Sender</span>
          </div>
          <div v-else-if="message.reason == 'sender_hopping'">
            <span class="badge badge-pill badge-light">Sender Hopping</span>
          </div>
          <div v-else>
            <span class="badge badge-pill badge-light">{{ message.reason }}</span>
          </div>

          <div v-if="message.fingerprint" style="cursor: pointer; margin-top: 7px">
            <div @click="clickedFingerprint(message.fingerprint)">
              <CIcon name="cil-fingerprint" style="width: 20px; height: 20px" />
            </div>
          </div>
        </div>

        <!-- -------------------------------- -->
        <div style="width: 650px; height: 70px; position: relative; display: inline-block; padding-top: 8px">
          <div v-if="message.is_spam" class="alert alert-danger" style="padding: 5px">
            <table width="100%">
              <tr>
                <td>
                  <span class="badge badge-pill badge-danger" style="cursor: pointer" @click="clickedSender(message.sender)">
                    {{ message.sender }}
                  </span>
                  <span style="font-size: 12px; ">
                    <span style="margin-left: 5px">filtered </span>
                    <timeago :datetime="message.received"></timeago>
                  </span>
                </td>
                <td align="right">
                  <span class="badge badge-pill badge-danger">
                    <span>{{ message.category }}</span>
                  </span>
                </td>
              </tr>
            </table>
            <div @click="displayMessage(message)" style="cursor: pointer">
              {{ truncateMessage(getMessage(message)) }}
            </div>
          </div>

          <div v-else class="alert alert-success" style="padding: 5px">
            <table width="100%">
              <tr>
                <td>
                  <span class="badge badge-pill badge-success" style="cursor: pointer" @click="clickedSender(message.sender)">
                    {{ message.sender }}
                  </span>
                  <span style="font-size: 12px; ">
                    <span style="margin-left: 5px">filtered </span>
                    <timeago :datetime="message.received"></timeago>
                  </span>
                </td>
                <td align="right">
                  <span class="badge badge-pill badge-success">
                    <span>{{ message.category }}</span>
                  </span>
                </td>
              </tr>
            </table>
            <div @click="displayMessage(message)" style="cursor: pointer">
              {{ truncateMessage(getMessage(message)) }}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <CModal :title="displayedSender" :color="displayedSpam ? 'danger' : 'success'" :show.sync="displayedMessage">
    <p style="font-family: monospace; white-space: pre-wrap">{{ displayedMessage }}</p>
    <template #footer>
      <CButton @click="clickedFind(displayedMessage)" :color="displayedSpam ? 'danger' : 'success'" style="width: 100px; margin-top: 5px">
        <CIcon name="cil-fingerprint" style="margin-right: 2px; margin-top: 0px" /> Find
      </CButton>

      <CButton v-if="displayedFingerprint" @click="clickedFingerprint(displayedFingerprint)" :color="displayedSpam ? 'danger' : 'success'" style="width: 180px; margin-top: 5px">
        <CIcon name="cil-contact" style="margin-right: 2px; margin-top: 0px" /> Fingerprint Profile
      </CButton>

      <CButton @click="clickedSender(displayedSender)" :color="displayedSpam ? 'danger' : 'success'" style="width: 150px; margin-top: 5px">
        <CIcon name="cil-contact" style="margin-right: 2px; margin-top: 0px" /> Sender Profile
      </CButton>

    </template>
  </CModal>

</div>
</template>

<script>
export default {
  name: 'Recent-Messages',
  data() {
    return {
      displayedFingerprint: null,
      displayedSpam: false,
      displayedSender: null,
      displayedMessage: null,
      errorMessage: null,
      fingerprintProfiles: {},
      isFetchingFingerprintProfiles: false,
      isRefreshing: false,
      messages: [],
      pendingFingerprintProfiles: {},
      refreshTimer: null,
      visibleVerticallyCenteredScrollableDemo: true
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedFingerprint(fingerprint) {
      this.$router.push({
        path: '/profiles/fingerprint/' + fingerprint
      });
    },
    //--------------------------------------------------------------------------
    clickedPause() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer);
        this.refreshTimer = null;
      }
    },
    //--------------------------------------------------------------------------
    clickedPlay() {
      if (this.refreshTimer == null) {
        this.refreshRecentMessages();
        var self = this;
        this.refreshTimer = setInterval(function() {
          self.refreshRecentMessages();
        }, 3000);
      }
    },
    //--------------------------------------------------------------------------
    clickedSender(sender) {
      this.$router.push({
        path: '/profiles/sender/' + encodeURIComponent(sender)
      });
    },
    clickedFind(message) {
      this.$router.push({
        path: '/sms-fingerprint/find/' + encodeURIComponent(message)
      });
    },
    //--------------------------------------------------------------------------
    displayMessage(message) {
      this.displayedMessage = this.getMessage(message);
      this.displayedSpam = message.is_spam;
      this.displayedSender = message.sender;
      this.displayedFingerprint = message.fingerprint;
    },
    //--------------------------------------------------------------------------
    formatDate(dateObj) {
      return dateObj.getHour();
    },
    //--------------------------------------------------------------------------
    formatExecutionTime(time) {
      return time
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {
      if (!response || !response.recent_messages) {
        this.errorMessage = "Received an unexpected response from the server";
        return;
      } else if (response.recent_messages.length == 0) {
        this.errorMessage = "Server did not return any recent messages";
        return;
      }

      // We only want to display up to 100 messages
      if (this.messages.length > 100) {
        this.messages = this.messages.slice(0, 100);
      }

      if (this.messages.length > 0) {
        if (response.recent_messages.length > 0) {
          response.recent_messages[0].id = Date.now();
          this.messages.unshift(response.recent_messages[0]);
        }
      } else {
        for (var x = 0; x < response.recent_messages.length; x++) {
          response.recent_messages[x].id = x;
        }
        this.messages = response.recent_messages;
      }

      // Queue the fingerprint profiles we need to fetch
      for (var x = 0; x < this.messages.length; x++) {
        var fingerprint = this.messages[x].fingerprint;
        if (!fingerprint) {
          continue;
        }
        if (this.fingerprintProfiles[fingerprint]) {
          continue
        }
        if (this.pendingFingerprintProfiles[fingerprint]) {
          continue;
        }
        this.pendingFingerprintProfiles[fingerprint] = true;
      }
      this.fetchFingerprintProfiles();
    },
    //--------------------------------------------------------------------------
    getMessage(message) {
      if (!message) {
        return "";
      }
      if (!message.fingerprint) {
        return message.message;
      }
      var profile = this.fingerprintProfiles[message.fingerprint];
      if (profile) {
        return this.anonymize(profile.obscured);
      }
      return message.message;
    },
    //--------------------------------------------------------------------------
    processFingerprintProfilesResponse(response) {
      if (!response || !response.profiles) {
        this.errorMessage = "Received an unexpected response from the server";
        return;
      } else if (response.profiles.length == 0) {
        this.errorMessage = "Server did not return any recent messages";
        return;
      }

      for (var x = 0; x < response.profiles.length; x++) {
        var fingerprint = response.profiles[x].fingerprint;
        this.fingerprintProfiles[fingerprint] = response.profiles[x];
        delete this.pendingFingerprintProfiles[fingerprint];
      }

      this.$forceUpdate();
    },
    //--------------------------------------------------------------------------
    fetchFingerprintProfiles() {
      // Make sure we have fingerprints to fetch
      if (Object.keys(this.pendingFingerprintProfiles).length == 0) {
        return;
      }

      if (this.isFetchingFingerprintProfiles) {
        return;
      } else {
        this.isFetchingFingerprintProfiles = true;
        this.errorMessage = null;
      }

      var maxProfiles = 200;
      var fingerprints = Object.keys(this.pendingFingerprintProfiles).slice(0, maxProfiles);

      var body = JSON.stringify({
        "fingerprints": fingerprints,
      });
      var that = this;
      var method = "POST";
      var url = "https://sms-profile.platform.robocalls.ai/fingerprint/profiles";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isFetchingFingerprintProfiles = false
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the profiles
            try {
              var result = JSON.parse(body);
              that.processFingerprintProfilesResponse(result);
              that.fetchFingerprintProfiles();
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isFetchingFingerprintProfiles = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isFetchingFingerprintProfiles = false;
          that.errorMessage = error;
        }
      })

    },
    //--------------------------------------------------------------------------
    anonymize(message) {
      if (!message) {
        return "";
      }
      message = message.replaceAll("[PERSON_NAME]", "🅿🅴🆁🆂🅾🅽");
      message = message.replaceAll("[LOCATION]", "🅻🅾🅲🅰🆃🅸🅾🅽");
      message = message.replaceAll("[DATE]", "🅳🅰🆃🅴");
      message = message.replaceAll("[URL]", "🆄🆁🅻");
      message = message.replaceAll("[PHONE_NUMBER]", "🅿🅷🅾🅽🅴");
      return message;
    },
    //--------------------------------------------------------------------------
    truncateMessage(message) {
      if (message.length > 85) {
        message = message.substring(0, 85) + "...";
      }
      return message;
    },
    //--------------------------------------------------------------------------
    refreshRecentMessages() {
      // Make sure we're not currently refreshing
      if (this.isRefreshing) {
        return;
      } else {
        this.errorMessage = null;
        this.isRefreshing = true;
      }

      // Fetch the recent messages
      var that = this;
      var method = "GET";
      var url = "https://sms-filter.platform.robocalls.ai/recent-messages";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshing = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshing = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshing = false;
          that.errorMessage = error;
        }
      })
    },
  },
  mounted() {
    // Every three seconds update the messages
    this.refreshRecentMessages();
    var self = this;
    this.refreshTimer = setInterval(function() {
      self.refreshRecentMessages();
    }, 3000);
  },
  beforeDestroy() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  },
}
</script>
